/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
import { Typography } from '@mui/material';
import CustomButton from 'components/CustomButton/customButton';
import { Loader } from 'components/loader';
import AnalyticsCheckboxSvg from 'components/SvgAsCode/Analyticscheckboxsvg';
import ResumevaliodationFlagSvg from 'components/SvgAsCode/ResumevalidationFlag';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { ReplaceData } from 'services/CommonServices';
import { RESUME_ENDPOINT } from 'utils/Constant';
import { StatusData } from 'utils/type';

import { HeaderTab } from './header';

type Props = {
  setAnalytics: (src: boolean) => void;
  closeModal?: (src: StatusData) => void;
  resumeId?: string;
};
const TabboxComp = ({
  dt2,
  idx,
  key,
  onClick,
  Content,
}: {
  dt2: any;
  idx: number;
  key: any;
  onClick: any;
  Content?: { heading: any; description: any };
}) => {
  const openLinkInNewWindow = (url: string) => {
    const features = 'width=800,height=600,left=100,top=100'; // Customize as needed
    window.open(
      `${url.includes('http') ? url : `https://www.linkedin.com/in/${url}`}`,
      '_blank',
      features,
    );
  };
  return (
    <>
      {' '}
      <div
        className={`infocontentBox ${
          dt2 && dt2.notmatch ? 'BorderRedAnalytics' : 'BorderGreenAnalytics'
        } ${dt2 && dt2.History ? 'infoHistoryBox' : ''}`}
        key={key}
        onClick={() => {
          if (Content.heading === dt2?.heading) {
            onClick ? onClick(undefined, undefined) : null;
            return;
          }
          onClick && ((dt2 && dt2.heading) || (dt2 && dt2.description))
            ? onClick(
                dt2 && dt2.heading ? dt2.heading : undefined,
                dt2 && dt2.description ? dt2.description : undefined,
              )
            : null;
        }}>
        {dt2 && dt2.label ? <div className="label">{dt2.label}</div> : <></>}
        <div className={'infoheader'}>
          <div
            className={`title ${
              Content && Content.heading === dt2?.title ? 'titleActiv' : ''
            }`}>
            {dt2 && dt2.title ? dt2.title : ''}
          </div>
          <div
            className="flagContainer"
            style={{ ...(dt2 && dt2.FlagOrg ? { paddingRight: '21px' } : {}) }}>
            {dt2 && dt2.link && dt2.linkTxt ? (
              <div
                className="link"
                onClick={() =>
                  dt2 && dt2.URL ? openLinkInNewWindow(dt2.URL) : undefined
                }>
                {dt2.linkTxt}
              </div>
            ) : (
              <></>
            )}
            {dt2 && dt2.flagTxt ? (
              <div className={`${dt2 && dt2.FlagOrg ? 'flagtxt2' : 'flagtxt'}`}>
                {dt2.flagTxt}
              </div>
            ) : (
              <></>
            )}
            {dt2 && dt2.flagColor ? (
              <div className="flagIcon">
                <ResumevaliodationFlagSvg Color={dt2.flagColor} />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {Content && Content.heading === dt2?.title ? (
          <div className="infomain">
            {Content && Content.description ? (
              <div
                className="description-line-break"
                dangerouslySetInnerHTML={{ __html: Content.description }}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

const TopContainer = [
  {
    data: [
      {
        FlagOrg: true,
        LinkIn: 'LinkedIn Profile Validation',
        flagTxt: 'YES',
        link: 'Click here to view LinkedIn profile',
        linkTxt: 'Click here to view LinkedIn profile',
        title: 'Was the profile identified',
      },
      {
        heading: 'Employment/Job Information',
        // flagColor: '#00BA00',
        score: '8/10',
        title: 'Employment/Job Information',
      },
      {
        heading: 'LinkedIn Account Verification Status',
        // flagColor: '#00BA00',
        title: 'LinkedIn Account Verification Status',
      },
      {
        heading: 'Mismatch in Total Experience',
        // flagColor: '#00BA00',
        title: 'Mismatch in Total Experience',
      },
      {
        data: [
          {
            heading: 'Profile created',
            // flagColor: '#FF0000',
            notmatch: true,
            title: 'Profile created',
          },
          {
            heading: 'Personal Details',
            // flagColor: '#FF0000',
            notmatch: true,
            title: 'Personal Details',
          },
          {
            heading: 'Educational Details',
            // flagColor: '#FF0000',
            notmatch: true,
            title: 'Educational Details',
          },
          {
            heading: 'Skill match to Resume',
            // flagColor: '#00BA00',
            title: 'Skill match to Resume',
          },
        ],
        subBox: true,
      },
    ],
    social: true,
    title: 'LinkedIn Profile Validation',
  },
  {
    data: [
      {
        heading: 'Compare skills between JD and Resume',
        // flagColor: '#00BA00',
        title: 'Compare skills between JD and Resume',
      },
    ],
    title: 'Job Description (JD) Keywords Analysis',
  },
  {
    // nodata: true,
    heading: 'Grammar & Spell Check - Score',
    title: 'Grammar & Spell Check - Score',
  },

  {
    data: [
      {
        History: true,
        data: [
          {
            History: true,
            label: 'Last Updated By',
            title: 'Maduvanthan',
          },
          {
            History: true,
            label: 'Last Updated On',
            title: '12/07/2014',
          },
        ],
      },
    ],
    title: 'Document History Analysis',
  },
];

export const Analytics: React.FC<Props> = (props: Props) => {
  const { setAnalytics, closeModal, resumeId } = props;
  const [checked, setchecked] = React.useState<boolean>(true);
  const [done, setdone] = React.useState(false);
  const [reason, setReason] = React.useState('');
  const [Score, setScore] = React.useState('');
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [ModalCon, setModalCon] = React.useState({
    description: undefined,
    heading: undefined,
    on: false,
  });
  const [loader, setLoader] = React.useState(false);
  function ModalOpener(header?: any, desc?: any) {
    console.warn(header);
    setModalCon((p: any) => ({
      ...p,
      description: desc ? desc : undefined,
      heading: header ? header : undefined,
      on: true,
    }));
  }
  // function ModalCloser() {
  //   setModalCon((p: any) => ({
  //     ...p,
  //     description: undefined,
  //     heading: undefined,
  //     on: false,
  //   }));
  // }
  const statusUpdateApi = () => {
    setLoadingButton(true);
    ReplaceData(
      resumeId,
      {
        comments: reason,
        status: checked ? StatusData.ACCEPTED : StatusData.REJECTED,
      },
      RESUME_ENDPOINT.ResumeValidationStatus,
      true,
    )
      .then(() => {
        setLoadingButton(false);
        closeModal &&
          closeModal(checked ? StatusData.ACCEPTED : StatusData.REJECTED);
      })
      .catch(() => setLoadingButton(false))
      .finally(() => {
        setLoadingButton(false);
      });
  };
  // const [topContainer, setTopContainer] = useState<any>(
  //   // analyticalData: [],
  //   [
  //     { title: 'Was the profile identified' },
  //     { title: 'Employment/Job Information' },
  //     { title: 'LinkedIn Profile Validation' },
  //     { title: 'Mismatch in Total Experience' },
  //     { title: 'Job Description (JD) Keywords Analysis' },
  //     { title: 'Grammar & Spell Check - Score' },
  //     { title: 'Document History Analysis' }, // 'Profile created',  // 'Personal Details',  // 'Educational Details',  // 'Skill match to Resume',
  //   ],
  // );
  function handleDone() {
    setdone((p) => !p);
  }
  const handleChecked = () => setchecked((p) => !p);

  const [topContainer, setTopContainer] = useState<any>([]);

  const formatNumber = (input: number): string | any => {
    const result = Math.round((Number(input) / 10) * 100) / 100;
    // If the number has more than two decimal places, round to two decimals
    return result;
  };
  const getAnalyticalData = (resumeId?: string) => {
    setLoader(true);
    getAllListData(
      `${String(RESUME_ENDPOINT.ResumeValidationGetAnalytical)}/${resumeId}`,
    )
      .then((res) => {
        setLoader(false);
        // console.log(res, 'yy');
        setScore(
          res?.data[0]?.overallScore ? `${res?.data[0]?.overallScore}` : '0',
        );
        const LocalResp = TopContainer.map((el: any, idx) => {
          if (
            el &&
            el.title.toLowerCase() ===
              String('Grammar & Spell Check - Score').toLowerCase()
          ) {
            return {
              description:
                res?.data[0]?.analytics?.grammerAndSpellingValidation?.reasons
                  ?.map((er: any) =>
                    er && er.reason ? `<span> ${er.reason} </span>` : undefined,
                  )
                  ?.join(' '),
              flagTxt: res?.data[0]?.analytics?.grammerAndSpellingValidation
                ?.scores?.score
                ? res?.data[0].analytics.jobDescription?.scores?.score
                : undefined,
              grammar: true,
              heading: 'Grammar & Spell Check - Score',
              score: res?.data[0]?.analytics?.grammerAndSpellingValidation
                ?.score
                ? `${Math.ceil(
                    parseInt(
                      formatNumber(
                        res?.data[0]?.analytics?.grammerAndSpellingValidation
                          ?.score,
                      ),
                    ),
                  )}/10`
                : '-',
              title: 'Grammar & Spell Check - Score',
            };
          }
          if (
            el &&
            el.title.toLowerCase() ===
              String('Document History Analysis').toLowerCase()
          ) {
            return {
              data:
                el && el.data
                  ? el.data.map((el1) => {
                      // eslint-disable-next-line no-empty

                      return {
                        ...el1,
                        data:
                          el1 && el1.data
                            ? el1.data.map((el2) => {
                                // eslint-disable-next-line no-empty
                                if (
                                  el2 &&
                                  el2.label &&
                                  el2.label === 'Last Updated By'
                                ) {
                                  return {
                                    ...el2,
                                    title:
                                      res?.data[0]?.analytics?.documentMetaData
                                        ?.author || '-',
                                  };
                                }
                                if (
                                  el2 &&
                                  el2.label &&
                                  el2.label === 'Last Updated On'
                                ) {
                                  return {
                                    ...el2,
                                    title:
                                      res?.data[0]?.analytics?.documentMetaData?.details?.find(
                                        (name) => name?.type === 'updatedAt',
                                      )?.gmt
                                        ? moment(
                                            res?.data[0]?.analytics?.documentMetaData?.details?.find(
                                              (name) =>
                                                name?.type === 'updatedAt',
                                            )?.gmt,
                                          ).format('MM/DD/YYYY')
                                        : '-',
                                  };
                                }
                              })
                            : undefined,
                      };
                    })
                  : undefined,
              title: 'Document History Analysis',
            };
          }
          if (
            el &&
            el.title.toLowerCase() ===
              String('Job Description (JD) Keywords Analysis').toLowerCase()
          ) {
            return {
              data:
                el && el.data
                  ? el.data.map((el1) => {
                      // eslint-disable-next-line no-empty
                      if (el1 && !el1.data) {
                        if (
                          el1.title &&
                          el1.title.toLowerCase() ===
                            String(
                              'Compare skills between JD and Resume',
                            ).toLowerCase()
                        ) {
                          return {
                            ...el1,
                            description:
                              res?.data[0]?.analytics?.jobDescription?.reasons
                                ?.map((er: any) =>
                                  er && er.reason
                                    ? `<span> ${er.reason} </span>`
                                    : undefined,
                                )
                                ?.join(' '),
                            flagTxt: res?.data[0]?.analytics?.jobDescription
                              ?.scores?.score
                              ? `${Math.ceil(
                                  parseInt(
                                    formatNumber(
                                      res?.data[0]?.analytics?.jobDescription
                                        ?.scores?.score,
                                    ),
                                  ),
                                )}/10`
                              : '-',
                          };
                        }
                      }
                    })
                  : undefined,
              title: 'Job Description (JD) Keywords Analysis',
            };
          }
          return el && el.data
            ? {
                ...el,
                data: el.data.map((el1: any, idx2) => {
                  if (el1 && !el1.data) {
                    if (
                      el1.title &&
                      el1.title.toLowerCase() ===
                        String('Was the profile identified').toLowerCase()
                    ) {
                      return {
                        ...el1,
                        URL: res?.data[0]?.linkedInUrl,
                        flagTxt: res?.data[0]?.isLinkedInVerified
                          ? 'YES'
                          : 'NO',
                      };
                    }
                    if (
                      el1.title &&
                      el1.title.toLowerCase() ===
                        String('Employment/Job Information').toLowerCase()
                    ) {
                      return {
                        ...el1,
                        description:
                          res?.data[0]?.analytics?.jobDescription?.reasons
                            ?.map((er: any) =>
                              er && er.reason
                                ? `<span> ${er.reason} </span>`
                                : undefined,
                            )
                            ?.join(''),
                        flagTxt: res?.data[0]?.analytics?.jobDescription?.scores
                          ?.score
                          ? `${Math.ceil(
                              parseInt(
                                formatNumber(
                                  res.data[0].analytics.jobDescription.scores
                                    .score,
                                ),
                              ),
                            )}/10`
                          : '-',
                      };
                    }
                    if (
                      el1.title &&
                      el1.title.toLowerCase() ===
                        String(
                          'LinkedIn Account Verification Status',
                        ).toLowerCase()
                    ) {
                      return {
                        ...el1,
                        // flagColor: null,
                        description:
                          res?.data[0]?.analytics?.linkedIn?.length &&
                          res?.data[0]?.analytics?.linkedIn
                            ?.find(
                              (eu) =>
                                eu.type &&
                                eu.type.toLowerCase() ===
                                  String(
                                    'linkedinProfileverified',
                                  ).toLowerCase(),
                            )
                            ?.reasons?.map((er: any) =>
                              er && er.reason
                                ? `<span> ${er.reason} </span>`
                                : undefined,
                            )
                            ?.join(' '),
                        flagTxt:
                          res?.data[0]?.analytics?.linkedIn?.length &&
                          res?.data[0]?.analytics?.linkedIn?.find(
                            (eu) =>
                              eu.type &&
                              eu.type.toLowerCase() ===
                                String('linkedinProfileverified').toLowerCase(),
                          ).score
                            ? `${Math.ceil(
                                parseInt(
                                  formatNumber(
                                    res?.data[0]?.analytics?.linkedIn?.find(
                                      (eu) =>
                                        eu?.type &&
                                        eu?.type.toLowerCase() ===
                                          String(
                                            'linkedinProfileverified',
                                          ).toLowerCase(),
                                    )?.score,
                                  ),
                                ),
                              )}/10`
                            : '-',
                      };
                    }
                    if (
                      el1.title &&
                      el1.title.toLowerCase() ===
                        String('Mismatch in Total Experience').toLowerCase()
                    ) {
                      return {
                        ...el1,
                        description:
                          res?.data[0]?.analytics?.linkedIn?.length &&
                          res?.data[0]?.analytics?.linkedIn
                            ?.find(
                              (eu) =>
                                eu.type &&
                                eu.type.toLowerCase() ===
                                  String('linkedinPersonalInfo').toLowerCase(),
                            )
                            ?.reasons?.map((er: any) =>
                              er && er.reason
                                ? `<span> ${er.reason} </span>`
                                : undefined,
                            )
                            ?.join(' '),
                        // flagColor: null,
                        flagTxt:
                          res?.data[0]?.analytics?.linkedIn?.length &&
                          res?.data[0]?.analytics?.linkedIn?.find(
                            (eu) =>
                              eu.type &&
                              eu.type.toLowerCase() ===
                                String('linkedinProfileverified').toLowerCase(),
                          ).score
                            ? `${Math.ceil(
                                parseInt(
                                  formatNumber(
                                    res.data[0].analytics.linkedIn.find(
                                      (eu) =>
                                        eu.type &&
                                        eu.type.toLowerCase() ===
                                          String(
                                            'linkedinProfileverified',
                                          ).toLowerCase(),
                                    ).score,
                                  ),
                                ),
                              )}/10`
                            : '-',
                      };
                    }
                  } else if (el1 && el1?.data) {
                    return {
                      data: el1?.data?.map((el11: any, idx2) => {
                        if (
                          el11.title &&
                          el11.title.toLowerCase() ===
                            String('Profile created').toLowerCase()
                        ) {
                          return {
                            ...el11,
                            // flagColor: null,
                            description:
                              res?.data[0]?.analytics?.linkedIn?.length &&
                              res?.data[0]?.analytics?.linkedIn
                                ?.find(
                                  (eu) =>
                                    eu.type &&
                                    eu.type.toLowerCase() ===
                                      String(
                                        'linkedinProfileCreated',
                                      ).toLowerCase(),
                                )
                                ?.reasons?.map((er: any) =>
                                  er && er.reason
                                    ? `<span> ${er.reason} </span>`
                                    : undefined,
                                )
                                ?.join('\n'),
                            flagTxt:
                              res?.data[0]?.analytics?.linkedIn?.length &&
                              res.data[0].analytics.linkedIn.find(
                                (eu) =>
                                  eu.type &&
                                  eu.type.toLowerCase() ===
                                    String(
                                      'linkedinProfileCreated',
                                    ).toLowerCase(),
                              ).score
                                ? `${Math.ceil(
                                    parseInt(
                                      formatNumber(
                                        res?.data[0]?.analytics?.linkedIn?.find(
                                          (eu) =>
                                            eu.type &&
                                            eu.type.toLowerCase() ===
                                              String(
                                                'linkedinProfileCreated',
                                              ).toLowerCase(),
                                        ).score,
                                      ),
                                    ),
                                  )}/10`
                                : '-',
                          };
                        }
                        if (
                          el11.title &&
                          el11.title.toLowerCase() ===
                            String('Personal Details').toLowerCase()
                        ) {
                          return {
                            ...el11,
                            // flagColor: null,
                            description:
                              res?.data[0]?.analytics?.linkedIn?.length &&
                              res?.data[0]?.analytics?.linkedIn
                                ?.find(
                                  (eu) =>
                                    eu.type &&
                                    eu.type.toLowerCase() ===
                                      String(
                                        'linkedinPersonalInfo',
                                      ).toLowerCase(),
                                )
                                ?.reasons?.map((er: any) =>
                                  er && er.reason
                                    ? `<span> ${er.reason} </span>`
                                    : undefined,
                                )
                                ?.join(' '),
                            flagTxt:
                              res?.data[0]?.analytics?.linkedIn?.length &&
                              res.data[0].analytics.linkedIn.find(
                                (eu) =>
                                  eu.type &&
                                  eu.type.toLowerCase() ===
                                    String(
                                      'linkedinPersonalInfo',
                                    ).toLowerCase(),
                              ).score
                                ? `${Math.ceil(
                                    parseInt(
                                      formatNumber(
                                        res.data[0].analytics.linkedIn.find(
                                          (eu) =>
                                            eu.type &&
                                            eu.type.toLowerCase() ===
                                              String(
                                                'linkedinPersonalInfo',
                                              ).toLowerCase(),
                                        ).score,
                                      ),
                                    ),
                                  )}/10`
                                : '-',
                          };
                        }
                        if (
                          el11.title &&
                          el11.title.toLowerCase() ===
                            String('Educational Details').toLowerCase()
                        ) {
                          return {
                            ...el11,
                            // flagColor: null,
                            description:
                              res?.data[0]?.analytics?.linkedIn?.length &&
                              res?.data[0]?.analytics?.linkedIn
                                ?.find(
                                  (eu) =>
                                    eu.type &&
                                    eu.type.toLowerCase() ===
                                      String(
                                        'linkedinEducationMatch',
                                      ).toLowerCase(),
                                )
                                ?.reasons?.map((er: any) =>
                                  er && er.reason
                                    ? `<span> ${er.reason} </span>`
                                    : undefined,
                                )
                                ?.join(' '),
                            flagTxt:
                              res?.data[0]?.analytics?.linkedIn?.length &&
                              res.data[0].analytics.linkedIn.find(
                                (eu) =>
                                  eu.type &&
                                  eu.type.toLowerCase() ===
                                    String(
                                      'linkedinEducationMatch',
                                    ).toLowerCase(),
                              ).score
                                ? `${Math.ceil(
                                    parseInt(
                                      formatNumber(
                                        res.data[0].analytics.linkedIn.find(
                                          (eu) =>
                                            eu.type &&
                                            eu.type.toLowerCase() ===
                                              String(
                                                'linkedinEducationMatch',
                                              ).toLowerCase(),
                                        ).score,
                                      ),
                                    ),
                                  )}/10`
                                : '-',
                          };
                        }
                        if (
                          el11.title &&
                          el11.title.toLowerCase() ===
                            String('Skill match to Resume').toLowerCase()
                        ) {
                          return {
                            ...el11,
                            // flagColor: null,
                            description:
                              res?.data[0]?.analytics?.linkedIn?.length &&
                              res?.data[0]?.analytics?.linkedIn
                                ?.find(
                                  (eu) =>
                                    eu.type &&
                                    eu.type.toLowerCase() ===
                                      String(
                                        'linkedinSkillMatch',
                                      ).toLowerCase(),
                                )
                                ?.reasons?.map((er: any) =>
                                  er && er.reason
                                    ? `<span> ${er.reason} </span>`
                                    : undefined,
                                )
                                ?.join(' '),
                            flagTxt:
                              res?.data[0]?.analytics?.linkedIn?.length &&
                              res.data[0].analytics.linkedIn.find(
                                (eu) =>
                                  eu.type &&
                                  eu.type.toLowerCase() ===
                                    String('linkedinSkillMatch').toLowerCase(),
                              ).score
                                ? `${Math.ceil(
                                    parseInt(
                                      formatNumber(
                                        res.data[0].analytics.linkedIn.find(
                                          (eu) =>
                                            eu.type &&
                                            eu.type.toLowerCase() ===
                                              String(
                                                'linkedinSkillMatch',
                                              ).toLowerCase(),
                                        ).score,
                                      ),
                                    ),
                                  )}/10`
                                : '-',
                          };
                        }
                      }),
                      subBox: true,
                    };
                  } else {
                    return;
                  }
                }),
              }
            : undefined;
        });

        setTopContainer(LocalResp);
        // setTopContainer((pre) => [
        //   { title: 'Was the profile identified' },
        //   { title: 'Employment/Job Information' },
        //   { title: 'LinkedIn Profile Validation' },
        //   { title: 'Mismatch in Total Experience' },
        //   { title: 'Job Description (JD) Keywords Analysis' },
        //   {
        //     title: 'Grammar & Spell Check - Score',
        //     // value: res.data.analytics.grammerAndSpellingValidation.score,
        //   },
        //   {
        //     title: 'Document History Analysis',
        //     // value: res.data.analytics.documentMetaData,
        //   },
        //   res.data,
        // ]);
      })
      .catch((err) => {
        setLoader(false);
        setTopContainer([]);
        console.log(err);
        // toast.error(err, alertOptions);
      });
  };

  useEffect(() => {
    getAnalyticalData(resumeId);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, react-hooks/exhaustive-deps
  }, [resumeId]);

  return (
    <>
      <Typography component={'div'}>
        <HeaderTab
          tab="analytics"
          setAnalytics={setAnalytics}
          hideAddNotes={true}
          FlagDropdown={true}
          Score={Score}
        />
        <Typography
          component={'div'}
          className="body-hight body-hight2"
          style={done ? { height: '45vh', opacity: 0.12 } : {}}>
          {loader ? (
            <Loader
              pageCenterLoadingHide={true}
              className={'d-flex-ja w-100 h-100'}
            />
          ) : topContainer && topContainer.length > 0 ? (
            topContainer.map((dt: any, idx: any) => {
              return (
                <>
                  <div
                    className={
                      dt && dt.grammar
                        ? 'grammar'
                        : `AnalyticalDetailscontainer ${
                            dt && dt.nodata ? 'AnalyticalDetailscontainer2' : ''
                          }`
                    }
                    key={idx}
                    onClick={() => {
                      if (dt && dt.grammar && dt.title === ModalCon.heading) {
                        ModalOpener(undefined, undefined);
                        return;
                      }

                      (dt && dt.heading ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: dt.heading,
                          }}
                        />
                      ) : undefined) ||
                      (dt && dt.description ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: dt.description,
                          }}
                        />
                      ) : undefined)
                        ? ModalOpener(
                            dt && dt.heading ? dt.heading : undefined,
                            dt && dt.description ? dt.description : undefined,
                          )
                        : undefined;

                      console.warn(dt);
                    }}>
                    <div className="heading">
                      <div
                        className={`headingtxt ${
                          dt && dt.grammar
                            ? `headingGram ${
                                dt &&
                                dt.grammar &&
                                dt.title === ModalCon.heading
                                  ? 'headingGramActv'
                                  : ''
                              }`
                            : ''
                        }`}>
                        {dt && dt.title ? dt.title : ''}
                      </div>
                      {dt && dt.score ? (
                        <div className="score">{dt.score}</div>
                      ) : (
                        ''
                      )}
                    </div>
                    {dt && dt.grammar && dt.title === ModalCon.heading ? (
                      <div className="infomain">
                        {dt && dt.description ? (
                          <div
                            className="description-line-break"
                            dangerouslySetInnerHTML={{
                              __html: dt.description,
                            }}
                          />
                        ) : null}
                      </div>
                    ) : null}
                    <div className="infocontainer">
                      {dt && dt.data ? (
                        dt.data.map((dt2, idx2) => {
                          if (
                            dt2 &&
                            dt2.subBox &&
                            dt2.data &&
                            dt2.data.length > 0
                          ) {
                            return (
                              <div className="subbox" key={idx2}>
                                {dt2.data.map((dt3: any, idx3: number) => {
                                  return (
                                    <TabboxComp
                                      dt2={dt3}
                                      idx={idx3}
                                      key={idx3}
                                      onClick={(heading, description) =>
                                        ModalOpener(
                                          heading ? heading : undefined,
                                          description ? description : undefined,
                                        )
                                      }
                                      Content={ModalCon}
                                    />
                                  );
                                })}
                              </div>
                            );
                          }
                          if (
                            dt2 &&
                            dt2.History &&
                            dt2.data &&
                            dt2.data.length > 0
                          ) {
                            return (
                              <div className="history" key={idx2}>
                                {dt2.data.map((dt3: any, idx3: number) => {
                                  return (
                                    <TabboxComp
                                      dt2={dt3}
                                      idx={idx3}
                                      key={idx3}
                                      onClick={(heading, description) =>
                                        ModalOpener(
                                          heading ? heading : undefined,
                                          description ? description : undefined,
                                        )
                                      }
                                      Content={ModalCon}
                                    />
                                  );
                                })}
                              </div>
                            );
                          }

                          return (
                            <TabboxComp
                              dt2={dt2}
                              idx={idx2}
                              key={idx2}
                              onClick={(heading, description) =>
                                ModalOpener(
                                  heading ? heading : undefined,
                                  description ? description : undefined,
                                )
                              }
                              Content={ModalCon}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <Typography
              component={'div'}
              className={'d-flex-ja w-100 h-100 red-color'}>
              No Records Found
            </Typography>
          )}
        </Typography>
        {done ? (
          <div className="reasoncontainer">
            <div className="inputContainer">
              <div className="label">Reason for accept / Reject</div>
              <textarea
                className="height-164"
                name=""
                id=""
                cols={30}
                rows={30}
                onChange={(e) => setReason(e.target.value)}
                value={reason}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <Typography component={'div'} className="Analyticsfooter">
          {done ? (
            <>
              <div className="reasonBtnContainer" style={{ width: '100%' }}>
                <div className="btn bt1" onClick={handleDone}>
                  cancel
                </div>
                {/* <div
                className={`btn bt2 ${
                  reason && reason.trim() ? '' : 'cursor-disable'
                } `}
                onClick={() =>
                  reason && reason.trim() ? statusUpdateApi() : {}
                }>
                Save
              </div> */}
                <CustomButton
                  variant="contained"
                  type={'button'}
                  isStyle={true}
                  name={'Save'}
                  loading={loadingButton}
                  className={`btn bt2 ${
                    reason && reason.trim() ? '' : 'cursor-disable'
                  } `}
                  onPress={() =>
                    reason && reason.trim() ? statusUpdateApi() : {}
                  }
                />
              </div>
            </>
          ) : (
            <>
              <div className="footerFirstBoc">
                <div className="txt">
                  Based on the above analysis, do you wish to Accept the resume
                  for the Job?
                </div>
                <div className="choice">
                  <div className="checkbox" onClick={handleChecked}>
                    <span className="svg">
                      <AnalyticsCheckboxSvg Selected={checked} />
                    </span>
                    <span className="txt2">Accept</span>
                  </div>
                  <div className="checkbox" onClick={handleChecked}>
                    <span className="svg">
                      <AnalyticsCheckboxSvg Selected={!checked} />
                    </span>
                    <span className="txt2">Reject</span>
                  </div>
                </div>
              </div>
              <div className="btn" onClick={handleDone}>
                Done
              </div>
            </>
          )}
        </Typography>
      </Typography>
      {/* {ModalCon.on ? (
        <div className="Analyticalpopup">
          <div
            style={{
              backgroundColor: 'transparent',
              bottom: '0px',
              height: '100%',
              left: '0px',
              position: 'absolute',
              right: '0px',
              top: '0px',
              width: '100%',
            }}
            onClick={ModalCloser}
          />
          <div className="Popupcontent">
            <header>
              <span className="txt">{ModalCon.heading}</span>
              <span className="closesvg" onClick={ModalCloser}>
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18">
                  <path
                    fill="#000"
                    d="M17.813.189a.645.645 0 00-.91 0l-7.9 7.9-7.905-7.9a.643.643 0 00-.91.91l7.905 7.9-7.901 7.903a.643.643 0 10.91.91l7.9-7.904 7.905 7.9a.643.643 0 00.906-.906L9.912 8.998l7.901-7.903a.643.643 0 000-.906z"
                  />
                </svg>
              </span>
            </header>
            <main style={{ overflow: 'auto' }}>{ModalCon.description}</main>
          </div>
        </div>
      ) : (
        <></>
      )} */}
    </>
  );
};
