/* eslint-disable react/prop-types */
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';

import MenuOpenIcon from '@mui/icons-material/MenuOpen';
// import RotateLeftIcon from '@mui/icons-material/RotateLeft';
// import RotateRightIcon from '@mui/icons-material/RotateRight';
// Import styles of default layout plugin
import {
  Grid,
  // Tooltip,
  Typography,
} from '@mui/material';
import {
  CharacterMap,
  // RotateDirection,
  // RenderPageProps,
  SetRenderRange,
  Viewer,
  VisiblePagesRange,
} from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
// import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
// default layout plugin
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
// import Loader from 'components/Loader';
import React, { useRef, useState } from 'react';

interface pdfProps {
  fileUrl: any;
  // jumpIndex?: any;
  // updateSearchPage?: any;
}

const PDFViewer: React.FC<any> = (pdfProps: pdfProps) => {
  const viewerRef = useRef<any>(null);
  const {
    fileUrl,
    //  jumpIndex,
    //   updateSearchPage
  } = pdfProps;

  const [sidePanalOpen, setSidePanalOpen] = useState<boolean>(false);
  // const [currentPage, setCurrentPage] = useState<number>(0);
  const toolbarPluginInstance = toolbarPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  // const rotatePluginInstance = rotatePlugin();
  // const { RotatePage } = rotatePluginInstance;
  // const { jumpToPage } = pageNavigationPluginInstance;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { Thumbnails } = thumbnailPluginInstance;
  const { Toolbar } = toolbarPluginInstance;
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const renderToolBar = (props: any) => {
    const { Download, GoToNextPage, GoToPreviousPage, Zoom, ZoomIn, ZoomOut } =
      props;

    return (
      <Grid
        md={12}
        sm={12}
        sx={{
          alignItems: 'center',
          display: 'flex',
          // padding: '18px'
          padding: '0px',
        }}>
        <Grid md={3} sx={{ display: 'flex', marginLeft: '20px' }}>
          <MenuOpenIcon
            className="pointer"
            onClick={() => setSidePanalOpen(!sidePanalOpen)}
            style={{ color: '#fff' }}
          />
        </Grid>

        <Grid md={2}>
          <Typography
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}>
            <ZoomOut />
            <Zoom />
            <ZoomIn />
          </Typography>
        </Grid>

        <Grid md={5}>
          <Typography
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
            {/* <RotatePage>
              {(props: {
                onRotatePage: (arg0: number, arg1: RotateDirection) => void;
              }) => (
                <Tooltip title={'Rotate left'} arrow>
                  <RotateLeftIcon
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgb(0 0 0 / 10%)',
                      },
                      color: '#fff!important',
                      cursor: 'pointer',
                      marginRight: '10px',
                    }}
                    onClick={() =>
                      props.onRotatePage(currentPage, RotateDirection.Backward)
                    }
                  />
                </Tooltip>
              )}
            </RotatePage> */}
            {/* <RotatePage>
              {(props: {
                onRotatePage: (arg0: number, arg1: RotateDirection) => void;
              }) => (
                <Tooltip title={'Rotate right'}>
                  <RotateRightIcon
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgb(0 0 0 / 10%)',
                      },
                      color: '#fff!important',
                      cursor: 'pointer',
                      marginRight: '10px',
                    }}
                    onClick={() =>
                      props.onRotatePage(currentPage, RotateDirection.Forward)
                    }
                  />
                </Tooltip>
              )}
            </RotatePage> */}
            <GoToPreviousPage />
            <GoToNextPage />
          </Typography>
        </Grid>
        <Grid md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography sx={{ alignItems: 'center', display: 'flex' }}>
            <Download />
            {/* <Print /> */}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const characterMap: CharacterMap = {
    isCompressed: true,
    // The url has to end with "/"
    url: 'https://unpkg.com/pdfjs-dist@2.6.347/cmaps/',
  };

  const setRenderRange: SetRenderRange = React.useCallback(
    (visiblePagesRange: VisiblePagesRange) => {
      return {
        endPage:
          visiblePagesRange.startPage <= 20
            ? Math.max(20, visiblePagesRange.endPage + 5)
            : visiblePagesRange.endPage + 5,
        startPage:
          visiblePagesRange.endPage <= 20 ? 0 : visiblePagesRange.startPage - 5,
      };
    },
    [],
  );

  // const handlePageChange = React.useCallback((data: any) => {
  //   setCurrentPage(data?.currentPage);
  //   // updateSearchPage(data?.currentPage + 1);
  // }, []);

  // useEffect(() => {
  //   jumpToPage(jumpIndex - 1);
  // }, [jumpIndex]);

  return (
    <Typography
      component={'div'}
      className="rpv-core__viewer"
      sx={{
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03) !important',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <Typography
        component={'div'}
        sx={{
          alignItems: 'center',
          backgroundColor: '#0262b9 !important',
          display: 'flex',
          // padding: '4px',
          padding: '0px !important',
        }}>
        <Toolbar>
          {(props: ToolbarSlot) => {
            return renderToolBar(props);
          }}
        </Toolbar>
      </Typography>
      <div
        className="rpv-core__viewer"
        style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          display: 'flex',
          height: '90%',
        }}>
        <div
          style={{
            borderRight: '1px solid rgba(0, 0, 0, 0.3)',

            display: !sidePanalOpen ? 'none' : '',
            // overflow: 'auto',
            width: '20%',
          }}>
          <Thumbnails />
        </div>
        <Typography
          component={'div'}
          sx={{
            // backgroundColor: '#e6e6e8 !important',
            flex: 1,
            overflow: 'hidden',
          }}>
          <Viewer
            fileUrl={fileUrl}
            defaultScale={1}
            ref={viewerRef}
            // onPageChange={(e: any) => handlePageChange(e)}
            plugins={[
              toolbarPluginInstance,
              thumbnailPluginInstance,
              pageNavigationPluginInstance,
              // rotatePluginInstance,
            ]}
            enableSmoothScroll={false}
            setRenderRange={setRenderRange}
            characterMap={characterMap}
          />
        </Typography>
      </div>
    </Typography>
  );
};

export default PDFViewer;
