import { Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomDatePicker from 'components/DatePicker';
import ProjectSkillsAddSvg from 'components/SvgAsCode/ProjectSkillsAddSvg';
import ProjectSkillsTrashSvg from 'components/SvgAsCode/ProjectSkillsTrashSvg';
import _ from 'lodash';
// import moment from 'moment';
import React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { GeneralDetails } from 'types';

import { CustomReactTagInput } from './CustomReactTagInput';

type Props = {
  control: UseFormReturn<GeneralDetails>;
  readOnly?: boolean;
};
export const ExperienceInfo: React.FC<Props> = (props: Props) => {
  const { control: formControl, readOnly } = props;
  const {
    control,
    formState,
    // getValues,
    // setError,
    // clearErrors,
    setValue,
    watch,
    trigger,
  } = formControl;

  const {
    fields,
    append,
    remove,
    // replace,
  } = useFieldArray({
    control,
    name: 'experience',
  });

  // React.useEffect(() => {
  //   append([
  //     {
  //       employer: '',
  //       fromDate: '',
  //       jobTitle: '',
  //       location: '',
  //       project: '',
  //       toDate: '',
  //     },
  //     {
  //       employer: '',
  //       fromDate: '',
  //       jobTitle: '',
  //       location: '',
  //       project: '',
  //       toDate: '',
  //     },
  //     {
  //       employer: '',
  //       fromDate: '',
  //       jobTitle: '',
  //       location: '',
  //       project: '',
  //       toDate: '',
  //     },
  //   ]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // function DateValidation(FromDate, ToDate, index: number) {
  //   const date1 = moment(FromDate);
  //   const date2 = moment(ToDate);

  //   console.log(date1, 'date1');
  //   console.log(date2, 'date2');

  //   console.log(date1.isAfter(date2));
  //   if (date1.isAfter(date2)) {
  //     setError(`experience.${index}.fromDate`, {
  //       message: 'From date must before To date',
  //       type: 'manual',
  //     });
  //   } else {
  //     clearErrors(`experience.${index}.fromDate`);
  //   }
  // }

  // function DateValidation(
  //   FromDate,
  //   ToDate,
  //   index: number,
  //   setError,
  //   clearErrors,
  // ) {
  //   const date1 = moment(FromDate);
  //   const date2 = moment(ToDate);

  //   console.log(date1, 'date1');
  //   console.log(date2, 'date2');

  //   console.log(date1.isAfter(date2), 'AGter');

  //   if (date1.isSameOrAfter(date2)) {
  //     setError(`experience.${index}.fromDate`, {
  //       message: 'From date must be before To date',
  //       type: 'manual',
  //     });
  //   } else {
  //     clearErrors(`experience.${index}.fromDate`);
  //   }
  // }
  const handleSkills = (id: number, value: any) => {
    return setValue(`experience.${id}.skillsMatched`, value);
  };

  const addField = () => {
    append({
      employerOrClient: '',
      fromDate: '',
      isCurrentlyWorking: false,
      jobTitle: '',
      location: '',
      project: '',
      skillsMatched: undefined,
      toDate: '',
    }); // Add a new field with a default value
  };
  const removeField = (idx: any) => {
    remove(idx);
  };

  return (
    <Typography component={'div'}>
      {fields && fields.length
        ? fields.map((item, index: number) => (
            <Grid
              container
              spacing={1}
              key={item.id}
              sx={
                fields.length === index + 1
                  ? {}
                  : {
                      borderBottom: '1px solid #ababff',
                      marginBottom: '10px',
                      paddingBottom: '10px',
                    }
              }>
              <Grid item md={2} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  {/* From Date */}
                  Period From
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.fromDate`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomDatePicker
                          className="custom-datepicker"
                          views="MM/YYYY"
                          name={name}
                          onChange={(e) => {
                            // DateValidation(
                            //   e,
                            //   getValues(`experience.${index}`).toDate,
                            //   index,
                            // )
                            // DateValidation(
                            //   e,
                            //   getValues(`experience.${index}.toDate`),
                            //   index,
                            //   setError,
                            //   clearErrors,
                            // );
                            onChange(e);
                            if (
                              !_.isEmpty(watch(`experience.${index}.toDate`))
                            ) {
                              trigger(`experience.${index}.fromDate`);
                              trigger(`experience.${index}.toDate`);
                            }
                          }}
                          value={value}
                          error={
                            formState.errors?.experience?.[index]?.fromDate
                              ?.message
                          }
                          {...ref}
                          disabled={readOnly ? true : false}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={2} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  {/* To Date */}
                  Period To
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.toDate`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomDatePicker
                          className="custom-datepicker"
                          views="MM/YYYY"
                          name={name}
                          onChange={(e) => {
                            // DateValidation(
                            //   getValues(`experience.${index}`).fromDate,
                            //   e,
                            //   index,
                            // );
                            // DateValidation(
                            //   getValues(`experience.${index}.fromDate`),
                            //   e,
                            //   index,
                            //   setError,
                            //   clearErrors,
                            // );
                            onChange(e);
                            if (
                              !_.isEmpty(watch(`experience.${index}.fromDate`))
                            ) {
                              // trigger(`experience.${index}.toDate`);
                              trigger(`experience.${index}.fromDate`);
                              trigger(`experience.${index}.toDate`);
                            }
                          }}
                          value={value}
                          error={
                            formState.errors?.experience?.[index]?.toDate
                              ?.message
                          }
                          {...ref}
                          disabled={readOnly ? true : false}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={3} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Job Title
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.jobTitle`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={
                            formState.errors?.experience?.[index]?.jobTitle
                              ?.message
                          }
                          disabled={readOnly ? true : false}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={3} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Employer/Client
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.employerOrClient`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(
                              e?.target?.value.replace(/[^a-zA-Z\s]/g, ''),
                            );
                          }}
                          error={
                            formState.errors?.experience?.[index]
                              ?.employerOrClient?.message
                          }
                          disabled={readOnly ? true : false}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={2} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Location
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.location`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={
                            formState.errors?.experience?.[index]?.location
                              ?.message
                          }
                          disabled={readOnly ? true : false}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Project
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.project`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={
                            formState.errors?.experience?.[index]?.project
                              ?.message
                          }
                          disabled={readOnly ? true : false}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={6.8} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Project Skill
                </Typography>
                <Typography
                  component={'div'}
                  className={
                    readOnly
                      ? 'projectskillcon projectskillconDisable'
                      : 'projectskillcon'
                  }>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.skillsMatched`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      if (fields.length - 1 === index) {
                        return (
                          // <CustomInput
                          //   placeHolder=""
                          //   value={value}
                          //   name={name}
                          //   className="custom-Input"
                          //   ref={ref}
                          //   onChange={(e: any) => {
                          //     onChange(e);
                          //   }}
                          //   error={
                          //     formState.errors?.experience?.[index]?.project
                          //       ?.message
                          //   }
                          // />
                          <CustomReactTagInput
                            // readOnly={readOnly}
                            value={value}
                            error={
                              formState.errors?.experience?.[index]
                                ?.skillsMatched?.message
                            }
                            handleSkills={(value) =>
                              !readOnly ? handleSkills(index, value) : null
                            }
                            lastItem={true}
                            addField={addField}
                            removeField={() =>
                              !readOnly ? removeField(index) : null
                            }
                          />
                        );
                      }
                      return (
                        // <CustomInput
                        //   placeHolder=""
                        //   value={value}
                        //   name={name}
                        //   className="custom-Input"
                        //   ref={ref}
                        //   onChange={(e: any) => {
                        //     onChange(e);
                        //   }}
                        //   error={
                        //     formState.errors?.experience?.[index]?.project
                        //       ?.message
                        //   }
                        // />
                        <CustomReactTagInput
                          value={value}
                          error={
                            formState.errors?.experience?.[index]?.skillsMatched
                              ?.message
                          }
                          handleSkills={(value) => handleSkills(index, value)}
                          addField={addField}
                          removeField={() => removeField(index)}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              {!readOnly && (
                <Grid item md={1.2} sm={12} sx={{ marginLeft: 'auto' }}>
                  <Typography
                    component={'label'}
                    className="input-title pb-2"
                    sx={{ opacity: 0 }}
                  />
                  <Typography
                    component={'div'}
                    className="flex-row"
                    style={{ transform: 'translateY(4px)' }}>
                    {fields.length == 1 && index <= 0 ? null : (
                      <Typography
                        component="div"
                        className="trashicon"
                        // style={tags && tags.length ? {} : { opacity: 0 }}
                        onClick={() => {
                          !readOnly && removeField(index);
                        }}>
                        <ProjectSkillsTrashSvg />
                      </Typography>
                    )}
                    {fields.length - 1 === index ? (
                      <Typography
                        component="div"
                        className="trashicon"
                        onClick={() => {
                          if (!readOnly && addField) {
                            addField ? addField() : null;
                          }
                        }}>
                        <ProjectSkillsAddSvg />
                      </Typography>
                    ) : null}
                  </Typography>
                </Grid>
              )}
            </Grid>
          ))
        : null}
    </Typography>
  );
};
