import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LaunchIcon from '@mui/icons-material/Launch';
import { FormHelperText, Grid, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import _ from 'lodash';
import React, { useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { getAllListData } from 'services/CommonServices';
import { GeneralDetails } from 'types';
import { MASTER_ENDPOINT, RESUME_ENDPOINT } from 'utils/Constant';
import {
  DocumentTypeInfo,
  DocumentTypeRespone,
  SourceInfo,
  SourceResponse,
  WorkAuthInfo,
  WorkAuthResponse,
} from 'utils/type';
import { linkedInUrlRegex } from 'utils/ValidatorSchema';

type Props = {
  control: UseFormReturn<GeneralDetails>;
  readOnly?: boolean;
  isFocused?: boolean;
  setIsFocused: (src: boolean) => void;
  show?: string;
  setShow?: (src: string) => void;
  linkedInVerification?: (src: boolean) => void;
};
export const ProfileInfo: React.FC<Props> = (props: Props) => {
  const {
    control: formControl,
    readOnly,
    isFocused,
    show,
    linkedInVerification,
  } = props;
  const {
    control,
    formState,
    // watch,
    getValues,
  } = formControl;
  const [sourceList, setSourceList] = useState<Array<SourceInfo>>([]);
  const [workStatus, setWorkStatus] = useState<Array<WorkAuthInfo>>([]);
  const [documentType, setDocumentType] = useState<Array<DocumentTypeInfo>>([]);

  const getSourceList = () => {
    getAllListData(`${RESUME_ENDPOINT.ResumeValidationsources}`)
      .then((resp: SourceResponse) => {
        setSourceList(resp.data);
      })
      .catch(() => {
        setSourceList([]);
      });
  };
  const getWorkStatus = () => {
    getAllListData(`${MASTER_ENDPOINT.WorkStatus}?status=true`)
      .then((resp: WorkAuthResponse) => {
        setWorkStatus(resp?.data);
      })
      .catch(() => {
        setWorkStatus([]);
      });
  };
  const getDocumentType = () => {
    getAllListData(
      `${RESUME_ENDPOINT.ResumeValidationDocumentTypes}?status=true`,
    )
      .then((resp: DocumentTypeRespone) => {
        setDocumentType(resp?.data);
      })
      .catch(() => {
        setDocumentType([]);
      });
  };

  React.useEffect(() => {
    getSourceList();
    getWorkStatus();
    getDocumentType();
  }, []);
  const handleLinkedInNavigation = () => {
    if (getValues('linkedinUrl')) {
      const url = getValues('linkedinUrl');
      const features = 'width=800,height=600,left=100,top=100'; // Customize as needed
      window.open(
        `${url.includes('http') ? url : `https://www.linkedin.com/in/${url}`}`,
        '_blank',
        features,
      );
    }
  };
  return (
    <Typography component={'div'}>
      <Grid container spacing={1}>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            First Name
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="firstName"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e?.target?.value.replace(/[^a-zA-Z\s]/g, ''));
                    }}
                    error={formState.errors?.firstName?.message}
                    disabled={readOnly ? true : false}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Last Name
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="lastName"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e?.target?.value.replace(/[^a-zA-Z\s]/g, ''));
                    }}
                    error={formState.errors?.lastName?.message}
                    disabled={readOnly ? true : false}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Primary Email
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="primaryEmail"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(
                        e?.target?.value.replace(
                          /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|}~(),:;<>@[\]]/g,
                          '',
                        ),
                      );
                    }}
                    error={formState.errors?.primaryEmail?.message}
                    disabled={readOnly ? true : false}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            City
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="city"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.city?.message}
                    disabled={readOnly ? true : false}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            State
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="state"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.state?.message}
                    disabled={readOnly ? true : false}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Job Title
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="jobTitle"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.jobTitle?.message}
                    disabled={readOnly ? true : false}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Phone Number
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="phoneNumber"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <PhoneInput
                    inputProps={{
                      name: name,
                      ...ref,
                    }}
                    inputClass="custom-phone-input"
                    country={formControl.getValues('countryCode') || 'us'}
                    inputStyle={{
                      border: '0px !important',
                    }}
                    value={value}
                    onChange={(val, data: any) => {
                      onChange(val);
                      formControl.setValue('countryCode', data?.dialCode);
                    }}
                    disabled={readOnly ? true : false}
                  />
                );
              }}
            />
          </Typography>
          {!readOnly && formState.errors?.phoneNumber?.message && (
            <FormHelperText className="errorMsg">
              {formState.errors?.phoneNumber?.message}
            </FormHelperText>
          )}
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography
            component={'label'}
            className="input-title pb-2 flex-align-lable">
            LinkedIn URL{' '}
            {show == 'success' && (
              <LaunchIcon
                sx={{ cursor: 'pointer', height: '18px' }}
                onClick={!readOnly && handleLinkedInNavigation}
              />
            )}
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="linkedinUrl"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <TextField
                    value={value}
                    name={name}
                    className="custom-Input-1"
                    ref={ref}
                    onChange={(e) => {
                      onChange(e?.target?.value.replace(/\s/g, ''));
                    }}
                    disabled={readOnly}
                    onBlur={() => {
                      if (
                        formControl.watch('linkedinUrl') &&
                        formControl.watch('linkedinUrl').trim() &&
                        linkedInUrlRegex.test(formControl.watch('linkedinUrl'))
                      ) {
                        linkedInVerification && linkedInVerification(true);
                      }
                    }}
                    InputProps={{
                      endAdornment:
                        formControl.watch('linkedinUrl') &&
                        formControl.watch('linkedinUrl').trim() &&
                        isFocused ? (
                          <InputAdornment
                            className="iconplacement"
                            position="start">
                            {show == 'success' ? (
                              <CheckCircleIcon style={{ color: '#6ab8ff' }} />
                            ) : show == 'cancel' ? (
                              <CancelIcon color="error" />
                            ) : (
                              <CircularProgress
                                sx={{
                                  '&.MuiCircularProgress-root': {
                                    height: '23px !important',
                                    width: '23px !important',
                                  },
                                }}
                              />
                            )}
                          </InputAdornment>
                        ) : null,
                    }}
                  />
                );
              }}
            />
          </Typography>
          {!readOnly && formState.errors?.linkedinUrl?.message && (
            <FormHelperText className="errorMsg">
              {formState.errors?.linkedinUrl?.message}
            </FormHelperText>
          )}
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Source
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="sourceId"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomSelect
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    value={value}
                    labelKey={'name'}
                    valueKey={'id'}
                    options={sourceList}
                    placeHolder={''}
                    className={'common-select'}
                    error={formState.errors?.sourceId?.message}
                    searchable={true}
                    name={name}
                    {...ref}
                    disabled={readOnly ? true : false}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Work Auth
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="workAuthId"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomSelect
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    value={value}
                    labelKey={'name'}
                    valueKey={'id'}
                    options={workStatus}
                    placeHolder={''}
                    className={'common-select'}
                    error={formState.errors?.workAuthId?.message}
                    searchable={true}
                    name={name}
                    {...ref}
                    disabled={readOnly ? true : false}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Document Type
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue={null}
              name="documentTypeId"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomSelect
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    value={value}
                    labelKey={'name'}
                    valueKey={'id'}
                    options={documentType}
                    placeHolder={''}
                    className={'common-select'}
                    error={formState.errors?.documentTypeId?.message}
                    searchable={false}
                    name={name}
                    {...ref}
                    disabled={readOnly ? true : false}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Document Type Number
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue={null}
              name="documentTypeNumber"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.documentTypeNumber?.message}
                    disabled={readOnly ? true : false}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};
