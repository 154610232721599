// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  //  IconButton,
  Typography,
} from '@mui/material';
import { CustomPDFViewer } from 'components/CustomPDFViewer';
// import saveAs from 'file-saver';
import React from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';

type Props = {
  url: string;
  hideDownload?: boolean;
  scale?: number;
};
export const PdfViewer: React.FC<Props> = (props: Props) => {
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const {
    url,
    // hideDownload,
    //  scale = 1
  } = props;

  // console.log(url, 'url');
  // console.log(hideDownload, 'hideDownload');

  // const [totalPages, setTotalPages] = React.useState(0);
  // const [pageNumber, setPageNumber] = React.useState(0);
  // const [pageScale, setPageScale] = React.useState(scale);
  // const [blob, setBlob] = React.useState<Blob | null>(null);
  // const onDocumentLoadSuccess = (pdf) => {
  //   setTotalPages(pdf.numPages);
  //   pdf.getData().then((arrayBuffer) => {
  //     const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
  //     setBlob(blob);
  //   });
  //   setPageNumber(1);
  // };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleZoomIn = () => {
  //   if (pageScale < 3) {
  //     setPageScale(pageScale + 0.2);
  //   }
  // };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleZoomOut = () => {
  //   if (pageScale > 0.3) {
  //     setPageScale(pageScale - 0.2);
  //   }
  // };

  // const handleNext = () => {
  //   if (pageNumber < totalPages) {
  //     setPageNumber(pageNumber + 1);
  //   }
  // };
  // const handlePrevious = () => {
  //   if (pageNumber > 0) {
  //     setPageNumber(pageNumber - 1);
  //   }
  // };
  // const candidateResumeDownload = () => {
  //   if (blob) {
  //     const fullName = Date.now();
  //     saveAs(blob, `${String(fullName)}.pdf`, {
  //       autoBom: true,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   onDocumentLoadSuccess();
  // }, []);
  return (
    <Typography component={'div'}>
      <div className="pdf-app-views">
        <div className="pdf-header-resume">
          <div className="pdf-button-container">
            {/* {hideDownload ? null : (
              <Typography
                component={'div'}
                className={`download-btn ${blob ? '' : 'not-allowed'}`}
                onClick={() => candidateResumeDownload()}>
                Download
              </Typography>
            )} */}
            {/* <div className="pdf-button-container">
              <IconButton
                aria-label="Zoom In"
                onClick={handleZoomIn}
                disabled={pageScale >= 3}
                color="primary">
                <ZoomInIcon />
              </IconButton>
              <IconButton
                aria-label="Zoom Out"
                onClick={handleZoomOut}
                disabled={pageScale <= 0.3}
                color="primary">
                <ZoomOutIcon />
              </IconButton>
            </div> */}
            {/* <IconButton
              aria-label="Previous"
              onClick={handlePrevious}
              disabled={pageNumber <= 1}
              color="error">
              <ArrowBackIosIcon sx={{ fontSize: '15px' }} />
            </IconButton> */}
            {/* {pageNumber}/ {totalPages} */}
            {/* <IconButton
              aria-label="Next"
              onClick={handleNext}
              disabled={pageNumber === totalPages}
              color="error">
              <ArrowForwardIosIcon sx={{ fontSize: '15px' }} />
            </IconButton> */}
          </div>
        </div>
        <div className="pdf-page-container">
          {/* <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} scale={pageScale} />
          </Document> */}
          <CustomPDFViewer fileUrl={url} />
        </div>
      </div>
    </Typography>
  );
};
