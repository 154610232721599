import { Typography } from '@mui/material';
// import ProjectSkillsAddSvg from 'components/SvgAsCode/ProjectSkillsAddSvg';
// import ProjectSkillsTrashSvg from 'components/SvgAsCode/ProjectSkillsTrashSvg';
import React, { useEffect, useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import { Tag } from 'react-tag-input/types/components/SingleTag';

export const CustomReactTagInput: React.FC<any> = (props) => {
  // const { value, error, handleSkills, lastItem, addField, removeField } = props;
  const { value, error, handleSkills } = props;

  // const suggestions = ['Bahrain', 'Bangladesh', 'Barbados', 'Belarus'].map(
  //   (country) => {
  //     return {
  //       className: '',
  //       id: country,
  //       text: country,
  //     };
  //   },
  // );
  // const [selected, setSelected] = useState(['papaya']);
  const [tags, setTags] = useState<Array<Tag>>([]);

  const handleDelete = (index: number) => {
    setTags(tags.filter((_, i) => i !== index));
    handleSkills
      ? handleSkills(
          tags.filter((_, i) => i !== index).map((item: any) => item.text),
        )
      : null;
  };
  useEffect(() => {
    if (value && value.length) {
      setTags(
        value.map((datas) => {
          return {
            className: '',
            id: datas,
            text: datas,
          };
        }),
      );
    } else {
      setTags([]);
    }
  }, [value]);

  // const onTagUpdate = (index: number, newTag: Tag) => {
  //   const updatedTags = [...tags];
  //   updatedTags.splice(index, 1, newTag);
  //   setTags(updatedTags);
  // };

  // const handleAddition = (tag: Tag) => {
  //   setTags((prevTags) => {
  //     return [...prevTags, tag];
  //   });
  // };

  // const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
  //   const newTags = tags.slice();

  //   newTags.splice(currPos, 1);
  //   newTags.splice(newPos, 0, tag);

  //   // re-render
  //   setTags(newTags);
  // };

  // const handleTagClick = (index: number) => {
  //   console.log('The tag at index ' + index + ' was clicked');
  // };

  const onClearAll = () => {
    setTags([]);
  };
  return (
    <Typography component={'div'} className={'TagsInputStyleCon'}>
      <Typography
        component={'div'}
        className={`TagsInputStyleConInner ${
          tags && tags.length
            ? tags.length < 3
              ? 'nonTagWidth'
              : ''
            : 'nonTagWidth'
        }`}>
        <Typography component={'div'} className={'TagsInputStyle'}>
          {tags ? (
            <ReactTags
              // readOnly={readOnly}
              tags={tags}
              inputFieldPosition="top"
              // suggestions={suggestions}
              handleDelete={handleDelete}
              // handleAddition={handleAddition}
              // handleDrag={handleDrag}
              // handleTagClick={handleTagClick}
              // onTagUpdate={onTagUpdate}
              // editable
              clearAll
              onClearAll={onClearAll}
              // maxTags={7}
              allowUnique={false}
              allowAdditionFromPaste
            />
          ) : null}
        </Typography>
        {error ? <Typography className="tagserror">{error}</Typography> : null}
      </Typography>

      {/* <Typography
        component="div"
        className="trashicon"
        // style={tags && tags.length ? {} : { opacity: 0 }}
        onClick={() => {
          if (removeField) {
            removeField ? removeField() : null;
          }
        }}>
        <ProjectSkillsTrashSvg />
      </Typography>
      {lastItem ? (
        <Typography
          component="div"
          className="trashicon"
          onClick={() => {
            if (addField) {
              addField ? addField() : null;
            }
          }}>
          <ProjectSkillsAddSvg />
        </Typography>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      )} */}
    </Typography>
  );
};
