import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { Worker } from '@react-pdf-viewer/core';
import React from 'react';

import PDFViewer from './PDFViewer';

export const CustomPDFViewer: React.FC<any> = ({ fileUrl }) => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
      <PDFViewer fileUrl={fileUrl} />;
    </Worker>
  );
};

export default React.memo(CustomPDFViewer);
