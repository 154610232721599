/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// src/ModalExample.tsx
import {
  Backdrop,
  // Backdrop,
  Box,
  Modal,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';

const style = {
  bgcolor: 'background.paper',

  border: '2px solid transparent',

  borderRadius: '10px',

  boxShadow: 24,

  height: 150,

  //   height: '500px',
  left: '50%',

  // minHeight: 768,
  // minHeight: 568,
  overflow: 'auto',
  // Set the max height to control the scrollable area
  overflowY: 'auto',

  p: 4,
  padding: '10px',

  position: 'absolute',

  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 444,
};
// Custom styled Backdrop
const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0)',
}));

export default function ResumeValActionModal({
  open,
  ClickFn,
  headerShown,
  SubmitFn,
  Success,
  Loader,
  JobListingData,
  JobTitle,
  ResumeId,
  Customer,
  ReadyMadeSourceId,
  placeHolder,
}: {
  open: boolean;
  ClickFn: any;
  headerShown?: boolean;
  SubmitFn?: any;
  ResumeId?: any;
  Success?: boolean;
  Loader?: boolean;
  JobListingData?: any;
  ReadyMadeSourceId?: any;
  JobTitle?: string;
  Customer?: string;
  placeHolder?: string;
}) {
  const [opn, setOpn] = useState(false);

  useEffect(() => {
    setOpn(open);
  }, [open]);

  function handleClose() {
    ClickFn();
  }

  return (
    <div>
      <Modal
        open={opn}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        BackdropComponent={CustomBackdrop}>
        <>
          <div className="modalBackdrop" onClick={handleClose} />
          <Box sx={style} className="UploadResumeinnerboxAction">
            {/* <header>
              <div
                className="closebtn"
                onClick={handleClose}
                style={{ zIndex: 3 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18">
                  <path
                    fill="#000"
                    d="M17.813.189a.645.645 0 00-.91 0l-7.9 7.9-7.905-7.9a.643.643 0 00-.91.91l7.905 7.9-7.901 7.903a.643.643 0 10.91.91l7.9-7.904 7.905 7.9a.643.643 0 00.906-.906L9.912 8.998l7.901-7.903a.643.643 0 000-.906z"
                  />
                </svg>
              </div>
            </header> */}
            <div className="ActionBody">
              {/* <div className="txt">Are You Sure! You Want To Proceed ?</div>
              <div className="btn" /> */}
              <>
                <div className="dustbinTxt">
                  <div className="txt">
                    Are You Sure! You Want To Proceed ?{' '}
                  </div>
                </div>
                <div className="mainBtn">
                  <div className="btn discard" onClick={handleClose}>
                    cancel
                  </div>
                  <div className="btn upload btn-red">Discard</div>
                </div>
              </>
            </div>
          </Box>
        </>
      </Modal>
    </div>
  );
}
