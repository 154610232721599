import { Box, Typography } from '@mui/material';
import { Loader } from 'components/loader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
// import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';

import { HeaderTab } from '../Analytics/header';
import { AddNotes } from './AddNotes';

type Props = {
  setAnalytics: (src: boolean) => void;
  candidateId: string;
};
export const ListNotes: React.FC<Props> = (props: Props) => {
  const { candidateId, setAnalytics } = props;
  const [shownNotes, setShownAddNotes] = useState<boolean>(false);
  const [notes, setNotes] = useState<any>([]);
  const [refreshNotes, setRefreshNotes] = useState<boolean>(false);
  const [loading, setLoading] = useState<{
    // button: boolean;
    common: boolean;
  }>({
    // button: false,
    common: true,
  });

  const getNotes = (id?: string) => {
    setLoading((pre) => ({
      ...pre,
      common: true,
    }));
    // const cId = id ? id : viewRow.id;
    getAllListData(
      MASTER_ENDPOINT.Candidates +
        '/notes?id=&userId=&candidateId=' +
        `${candidateId}`,
    )
      .then((resp: any) => {
        setNotes(resp?.data);
        setRefreshNotes(false);
        setLoading((pre) => ({
          ...pre,
          common: false,
        }));
      })
      .catch((err: any) => {
        setLoading((pre) => ({
          ...pre,
          common: false,
        }));
        setRefreshNotes(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshNotes]);

  return (
    <Typography component={'div'}>
      <HeaderTab
        tab="notes"
        setAnalytics={setAnalytics}
        setShownAddNotes={setShownAddNotes}
        shownNotes={shownNotes}
        notesTotal={notes.length}
      />
      {shownNotes && (
        <AddNotes
          closeNotes={() => setShownAddNotes(false)}
          setRefreshNotes={setRefreshNotes}
          candidateId={candidateId}
        />
      )}
      {/* <Typography component={'div'} className="body-hight">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((e) => (
        <Box className="notes-card-info mt-12" key={e}>
          <Typography component={'div'} className="info">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry&apos;s standard dummy
            text ever since the 1500s, when an unknown printer took a galley
            of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was
            popularised in the 1960s
          </Typography>

          <Typography
            component={'div'}
            className="d-flex-a flex-sb footer-info">
            <Typography component={'div'} className="title">
              Created bY &nbsp;{' '}
              <span className="red-color f-12 ">Anger Westerveli</span>
            </Typography>
            <Typography component={'div'} className="title">
              Created Date &nbsp;{' '}
              <span className="red-color f-12 ">10/08/2024</span>
            </Typography>
          </Typography>
        </Box>
      ))} */}

      {loading.common ? (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: 'calc(88vh - 75px)',
            justifyContent: 'center',
          }}>
          <Loader
            pageCenterLoadingHide={true}
            className={'d-flex-ja w-100 h-100'}
            // loaderColor="#bf0000"
          />
        </Box>
      ) : (
        <Typography component={'div'} className="body-hight">
          {notes && notes.length > 0 ? (
            notes.map((data, index: number) => (
              <Box className="notes-card-info mt-12" key={index}>
                <Typography
                  component={'div'}
                  className="info"
                  sx={{
                    wordBreak: ' break-all',
                  }}>
                  {data.notes}
                </Typography>
                <Typography
                  sx={{
                    marginTop: '45px',
                  }}
                  component={'div'}
                  className="d-flex-a flex-sb footer-info">
                  <Typography component={'div'} className="title">
                    Created by &nbsp;{' '}
                    <span className="red-color f-12">
                      {data.user.firstName} {data.user.lastName}{' '}
                    </span>
                  </Typography>
                  <Typography component={'div'} className="title">
                    Created Date &nbsp;{' '}
                    <span className="red-color f-12">
                      {moment(data.createdAt).format('MM/DD/YYYY')}
                    </span>
                  </Typography>
                </Typography>
              </Box>
            ))
          ) : (
            <Typography
              component={'div'}
              className={'d-flex-ja w-100 h-100 red-color'}>
              No Records Found
            </Typography>
          )}
        </Typography>
      )}

      <Typography component={'div'} className="footer-info">
        <Typography component={'div'} className="resume-btn-footer">
          Next
        </Typography>
      </Typography>
    </Typography>
  );
};
